<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="4"><v-select hide-details label="* Idioma:" :items="selectIdioma" v-model="registro.id_disciplina" /></v-col>
                            <v-col cols="12" sm="4"><v-select hide-details label="* Nível:" :items="selectNivel" v-model="registro.id_nivel" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field hide-details label="Código serial do material:" v-model="registro.serial" v-mask="'XXXX-XXX-XXX'" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-7"><div v-html="erro"></div></v-alert>
                    <p class="pt-3">O material é adquirido diretamente com a editora.</p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'

export default {
    name: "AlunoNivelForm",
    props : ['id_aluno'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectIdioma : [],
            selectNivel : []
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        selectNivelListar() {
            return axios.post(`${this.apiUrl}aluno/level/listar`).then((res) => {
                this.selectNivel = res.data.lista.map((rt) => {
                    return {value : rt.id_level, text : rt.level}
                })
            })
        },
        selectIdiomaListar() {
            return axios.post(`${this.baseUrl}disciplina/list`).then((res) => {
                this.selectIdioma = res.data.lista.map((rt) => {
                    return {value : rt.id_disciplina, text : rt.disciplina}
                })
            })
        },
        salvar() {
            axios.post(`${this.apiUrl}aluno/nivel/novo`, {
                id_disciplina : parseInt(this.registro.id_disciplina),
                id_nivel      : parseInt(this.registro.id_nivel),
                serial        : (this.registro.serial || '').replaceAll('-', '').toUpperCase(),
                id_aluno      : parseInt(this.id_aluno)
            }).then( () => {
                this.erro = null
                this.$router.push({path : '/aluno/gerenciar/' + this.id_aluno})
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        },
        async init() {
            this.registro = {}
            await this.selectIdiomaListar()
            await this.selectNivelListar()
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
